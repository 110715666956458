import * as React from 'react';

import * as Styled from './radio-button.styles';
import type { RadioButtonProps } from './radio-button.types';

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    { className, disabled, error, name, on, onChange, value, ...props },
    forwardedRef
  ): JSX.Element => (
    <Styled.RadioButton
      $error={error}
      aria-invalid={error ? true : undefined}
      className={className}
      data-xds="RadioButton"
      disabled={disabled}
      name={name}
      onChange={onChange}
      ref={forwardedRef}
      type="radio"
      value={value}
      {...{ [onChange ? 'checked' : 'defaultChecked']: on }}
      {...props}
    />
  )
);

RadioButton.displayName = 'RadioButton';
